import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments';
import {FLOORPLAN_SERVICES, ServiceEnum, ServiceStatus, StorageTarget, VIDEO_SERVICES} from '../../../enums';
import {Job, Service} from '../../../models';
import {JobsService} from '../../../services';
import {UploadService} from '../../../services/upload.service';
import {UploadDropzoneComponent} from '../../upload-dropzone/upload-dropzone.component';

@Component({
    selector: 'prism-video-floorplan-upload',
    templateUrl: './video-floorplan-upload.component.html',
    styleUrls: ['./video-floorplan-upload.component.scss']
})
export class VideoFloorplanUploadComponent implements OnInit {
    @ViewChild('uploadDropzone', {static: true}) uploadDropzoneComponent: UploadDropzoneComponent;
    @Input() service: Service;
    @Input() productId: string;
    @Input() serviceIndex: number;
    @Input() parentJob: Job;
    @Input() onlyEdit: boolean;

    @Input()
    set productsLoading(loading: boolean) {
        if (loading === this.isProductsLoading) {
            return;
        }
        this.isProductsLoading = loading;
    }

    @Output() serviceBoxClosed: EventEmitter<{
        uploadComponent: any,
        onlyEdit: boolean,
        destination: any,
        service: Service,
        cancelled: boolean
    }> = new EventEmitter<{ uploadComponent: any, onlyEdit: boolean, destination: any, service: Service, cancelled: boolean }>();

    @Output() closeAllDialogHandler: EventEmitter<{ cancelled: boolean, fileCount: number, serviceId: string }> = new EventEmitter<{
        cancelled: boolean,
        fileCount: number,
        serviceId: string
    }>();

    isProductsLoading: boolean;
    serviceId: string;
    allowedFileType: string;
    doesNotExistOnProcessor: boolean;
    statusPreEdit: string;
    serviceForm: FormGroup;
    closeServiceUploader: boolean;
    fileCount: number;
    briefFormOpened: boolean;
    assetsHandled: boolean;
    loadingInProgress: boolean;
    uploadLimited: Observable<any>;
    videoServiceFormExpanded: boolean;
    brief: any;
    hideService: boolean;

    constructor(private formBuilder: FormBuilder, private jobService: JobsService, private uploadService: UploadService) {
        this.parentJob = this.jobService.selectedJob;
        this.closeServiceUploader = false;
        this.fileCount = 0;
        this.briefFormOpened = false;
        this.assetsHandled = false;
        this.loadingInProgress = false;
        this.uploadLimited = this.uploadService.uploadLimited$;
        this.statusPreEdit = null;
        this.videoServiceFormExpanded = false;
        this.hideService = false;
    }

    ngOnInit() {
        this.serviceForm = this.createServiceForm();
        this.brief = this.serviceForm.getRawValue();
        this.doesNotExistOnProcessor = !this.onlyEdit || (this.service.status === ServiceStatus.draft || this.service.status === ServiceStatus.noProcessing);
        this.statusPreEdit = !!this.service && this.onlyEdit ? this.service.status : null;

        switch (this.service.SNAKE_CASE) {
            case ServiceEnum.VIDEO_SERVICES: {
                this.allowedFileType = 'video';
                break;
            }
            case ServiceEnum.FLOORPLAN_SERVICES: {
                this.allowedFileType = 'image';
            }
        }
        this.uploadService.uploadLimited$.subscribe();
    }

    handleProcessingRequiredSelection(required: boolean) {
        this.serviceForm.get('processingRequired').setValue(required);
        this.brief = this.serviceForm.getRawValue();
    }

    setFileCount(filesCount: number) {
        this.fileCount = filesCount;
    }

    onCancelClicked() {
        this.loadingInProgress = true;
        this.hideService = true;
        this.uploadService.removeUploadLimited(this.service.id);
        this.triggerAssetUploaderHandler(null, true);
        this.closeDialog(true);
    }

    startUpload() {
        const required = this.serviceForm.get('processingRequired').getRawValue();
        if (required) {
            this.sendAssetsToProcessor();
        } else {
            this.saveAssetsToMember();
        }
    }

    saveAssetsToMember() {
        this.closeDialog(false);
        this.loadingInProgress = true;
        this.uploadService.removeUploadLimited(this.service.id);
        this.serviceForm.get('status').setValue(ServiceStatus.uploadInProgress);
        this.brief = this.serviceForm.getRawValue();
        this.hideService = true;
        this.triggerAssetUploaderHandler(StorageTarget.MEMBER);
    }

    sendAssetsToProcessor() {
        this.loadingInProgress = true;
        this.uploadService.removeUploadLimited(this.service.id);
        this.serviceForm.get('status').setValue(ServiceStatus.uploadInProgress);
        this.brief = this.serviceForm.getRawValue();
        setTimeout(() => {
            this.triggerAssetUploaderHandler(StorageTarget.PROCESSOR);
        }, 100);
    }

    triggerAssetUploaderHandler(destination: StorageTarget.PROCESSOR | StorageTarget.MEMBER, cancelled: boolean = false) {
        try {
            if (!this.onlyEdit && !this.serviceId) {
                this.serviceId = this.service.id;
                this.serviceForm.get('id').setValue(this.serviceId);
                this.brief = this.serviceForm.getRawValue();
                this.serviceForm.updateValueAndValidity();
            }
            if (this.onlyEdit) {
                if (!cancelled) {
                    this.uploadDropzoneComponent.startUpload(this.parentJob.id, destination, this.serviceId);
                    const serviceStatus = this.statusPreEdit;

                    if (destination === StorageTarget.PROCESSOR && (serviceStatus === ServiceStatus.draft || serviceStatus === ServiceStatus.noProcessing)) {
                        if (this.service.version !== 'v2') {
                            this.hideService = true;
                            this.openGoogleFormLink();
                        }
                    }
                    this.closeAllDialogHandler.emit({cancelled: false, fileCount: this.fileCount, serviceId: this.service.id});
                }

            } else {
                if (!cancelled) {
                    if (!this.briefFormOpened && this.serviceForm.get('processingRequired').value) {
                        this.briefFormOpened = true;
                        if (this.service.version !== 'v2') {
                            this.openGoogleFormLink();
                            this.closeDialog(false);
                            this.hideService = true;
                        }

                        if (this.assetsHandled) {
                            this.closeServiceUploader = true;
                        }
                    }

                    this.serviceForm.get('DISPLAY').setValue(this.service.DISPLAY);
                    this.serviceForm.get('SNAKE_CASE').setValue(this.service.SNAKE_CASE);
                    this.brief = this.serviceForm.getRawValue();

                    if (!!destination) {
                        this.serviceBoxClosed.emit({
                            service: {...this.serviceForm.getRawValue(), version: this.service.version},
                            cancelled,
                            onlyEdit: this.onlyEdit,
                            destination,
                            uploadComponent: this.uploadDropzoneComponent
                        });
                    }

                } else {
                    this.serviceForm.reset();
                    this.serviceForm.get('id').setValue(this.serviceId);
                    this.serviceForm.get('status').setValue(ServiceStatus.draft);
                    this.serviceForm.get('DISPLAY').setValue(this.service.DISPLAY);
                    this.serviceForm.get('SNAKE_CASE').setValue(this.service.SNAKE_CASE);
                    this.brief = this.serviceForm.getRawValue();
                    this.serviceBoxClosed.emit({
                        service: {...this.serviceForm.getRawValue(), version: this.service.version},
                        cancelled,
                        onlyEdit: this.onlyEdit,
                        destination,
                        uploadComponent: this.uploadDropzoneComponent
                    });
                }
            }
            this.serviceForm.disable();
            this.handleModalExitOnAssetsAdded();
        } catch (e) {
            console.error('Error triggering upload handler for form upload component:', e);
        }
    }

    handleModalExitOnAssetsAdded() {
        this.closeServiceUploader = true;
        if (this.briefFormOpened) {
            this.closeServiceUploader = true;
        } else {
            this.assetsHandled = true;
        }
    }

    createServiceForm() {
        return this.formBuilder.group({
            id: new FormControl(this.serviceId || null),
            serviceName: new FormControl(null),
            processingRequired: new FormControl(true),
            DISPLAY: new FormControl(this.service?.DISPLAY || ''),
            SNAKE_CASE: new FormControl(this.service?.SNAKE_CASE || ''),
            status: new FormControl(this.service?.status || ''),
            brief: new FormControl(''),
            numOfFinals: new FormControl(0, [Validators.min(1), Validators.pattern(/^\d*?$/)])
        });
    }

    serviceUploading(loading: boolean) {
        if (this.service.version === 'v2') {
            if (!loading) {
                this.hideService = true;
                this.loadingInProgress = false;
                this.closeDialog(false);
            } else {
                this.loadingInProgress = true;
            }
        }
    }

    closeDialog(cancelled: boolean) {
        this.closeAllDialogHandler.emit({cancelled: cancelled, fileCount: this.fileCount, serviceId: this.service.id});
    }

    openGoogleFormLink() {
        switch (this.service.DISPLAY) {
            case VIDEO_SERVICES.DISPLAY: {
                window.open(`${environment.google_forms.video}`, '_blank');
                break;
            }
            case FLOORPLAN_SERVICES.DISPLAY: {
                window.open(`${environment.google_forms.floorplan}`, '_blank');
                break;
            }
        }
    }
}