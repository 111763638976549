import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddEditInvoiceComponent} from 'src/app/components/add-edit-invoice/add-edit-invoice.component';
import {AddJobProductComponent} from 'src/app/components/add-job-product/add-job-product.component';
import {VideoFloorplanUploadComponent} from '../../components';
import {ImageUploadComponent} from '../../components';
import {ConfirmDefaultCardComponent} from 'src/app/components/confirm-default-card/confirm-default-card.component';
import {ConfirmRejectionComponent} from 'src/app/components/confirm-rejection/confirm-rejection.component';
import {AddEditMemberProductComponent} from '../../components';
import {ServiceListComponent} from '../../components';
import {DownloadSelectionModalComponent} from 'src/app/components/download-selection-modal/download-selection-modal.component';
import {
    FilterDownloadSelectionModalComponent
} from 'src/app/components/filter-download-selection-modal/filter-download-selection-modal.component';
import {FixMemberAccountCodeComponent} from 'src/app/components/invoices-page/fix-member-account-code/fix-member-account-code.component';
import {InvoicesCsvFetchComponent} from 'src/app/components/invoices-page/invoices-csv-fetch/invoices-csv-fetch.component';
import {InvoicesPageComponent} from 'src/app/components/invoices-page/invoices-page.component';
import {InvoicesTableComponent} from 'src/app/components/invoices-page/invoices-table/invoices-table.component';
import {UnsyncedInvoicesTableComponent} from 'src/app/components/invoices-page/unsynced-invoices-table/unsynced-invoices-table.component';
import {AssetDisplayComponent} from 'src/app/components/job-detail/asset-display/asset-display.component';
import {BriefModalComponent} from 'src/app/components/job-detail/brief-modal/brief-modal.component';
import {JobDetailTableComponent} from 'src/app/components/job-detail/job-detail-table/job-detail-table.component';
import {JobDetailComponent} from 'src/app/components/job-detail/job-detail.component';
import {NotifyAgentDialogComponent} from 'src/app/components/notify-agent-dialog/notify-agent-dialog.component';
import {FinalDisplayComponent} from 'src/app/components/product-carousel/final-display/final-display.component';
import {ProductCarouselComponent} from 'src/app/components/product-carousel/product-carousel.component';
import {ProductsPageComponent} from 'src/app/components/products-page/products-page.component';
import {ProductsTableComponent} from 'src/app/components/products-page/products-table/products-table.component';
import {StripeElementsComponent} from 'src/app/components/stripe-elements/stripe-elements.component';
import {
    DownloadAllSelectedAssetsModalComponent
} from '../../components/download-all-selected-assets/download-all-selected-assets-modal.component';
import {HomeRouting} from '../routing/home.routing';
import {SharedModule} from './shared.module';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ServiceBriefFormComponent} from '../../components/service-brief/service-brief-form/service-brief-form.component';
import {FormUploadDialogComponent} from '../../components/form-upload-dialog/form-upload-dialog.component';
import {ServiceBriefDialogComponent} from '../../components/service-brief/service-brief-dialog/service-brief-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        HomeRouting,
        AngularSvgIconModule
    ],
    declarations: [
        AddEditInvoiceComponent,
        AddJobProductComponent,
        AssetDisplayComponent,
        BriefModalComponent,
        ConfirmDefaultCardComponent,
        ConfirmRejectionComponent,
        AddEditMemberProductComponent,
        FinalDisplayComponent,
        InvoicesPageComponent,
        InvoicesTableComponent,
        VideoFloorplanUploadComponent,
        FormUploadDialogComponent,
        ServiceBriefFormComponent,
        ServiceBriefDialogComponent,
        JobDetailComponent,
        JobDetailTableComponent,
        NotifyAgentDialogComponent,
        ProductsPageComponent,
        ProductCarouselComponent,
        ProductsTableComponent,
        ServiceListComponent,
        ImageUploadComponent,
        StripeElementsComponent,
        UnsyncedInvoicesTableComponent,
        FixMemberAccountCodeComponent,
        InvoicesCsvFetchComponent,
        DownloadSelectionModalComponent,
        FilterDownloadSelectionModalComponent,
        DownloadAllSelectedAssetsModalComponent
    ],
    exports: [
        FinalDisplayComponent
    ]
})

export class HomeModule {

}
